.exchange {
    .title-row {
        margin-bottom: 2.5rem;
    }

    .companies {
        justify-content: space-between;
        max-width: 1110px;
        margin: auto;

        .form {
            margin-bottom: 1.875rem;
        }

        .tile--horizontal {
            .tile__desc {
                padding: 1.875rem;
            }
        }
    }
}
