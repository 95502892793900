button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &::before {
        margin-right: 0.375rem;
    }

    &::after {
        margin-left: 0.375rem;
    }
}

.ui_button {
    margin: 0 1rem;

    &::before {
        margin-right: 0.375rem;
    }

    &::after {
        margin-left: 0.375rem;
    }
}

.ui_button:first-child {
    margin-left: 0;
}

.ui_button.publish {
    display: flex;
    align-items: center;
    color: black;
    margin-right: 0;
    &::after {
        content: url('/layout/default/dist/gfx/icons/publish.png');
        height: 28px;
    }
}

.publish-before {
    display: flex;
    &::before {
        content: url('/layout/default/dist/gfx/icons/publish-small.png');
        height: 21px;
    }
}

.ui_button.no-publish {
    display: flex;
    align-items: center;
    margin-right: 0;
    color: black;
    &::after {
        content: url('/layout/default/dist/gfx/icons/no-publish.png');
        height: 20px;
    }
}

.ui_button.cancel-publish {
    display: flex;
    align-items: center;

    &::before {
        content: url('/layout/default/dist/gfx/icons/cancel-preview.png');
        height: 21px;
    }
}

.ui_button.preview {
    display: flex;
    align-items: center;

    &::before {
        content: url('/layout/default/dist/gfx/icons/preview.png');
        height: 17px;
    }
}

.save {
    display: flex;

    &::before {
        content: url('/layout/default/dist/gfx/icons/save.png');
        height: 22px;
    }
}

.ui_button.edit {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;

    &::before {
        content: url('/layout/default/dist/gfx/icons/edit.png');
        height: 21px;
    }
}

.ui_button.delete {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;

    &::before {
        content: url('/layout/default/dist/gfx/icons/trash.png');
        height: 21px;
    }
}

.ui_button.add {
    color: black;
    text-decoration: none;
}

.menu-ui {
    position: initial;
    border-bottom: none;

    .nav-item {
        padding: 0 2.625rem !important;
        a {
            font-size: 1.25rem;
        }

        .presskit {
            &::after {
                content: url('/layout/default/dist/gfx/icons/presskit-icon.png');
                margin-left: 0.5rem;
            }
        }
    }

    @include media-breakpoint-up(xs) {
        display: none;

        .navbar-nav {
            width: 100%;

            .nav-item:first-child {
                padding-left: 2.625rem !important;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: flex;

        .navbar-nav {
            .nav-item:first-child {
                padding-left: 0 !important;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .navbar-nav {
            width: unset;
        }
    }
}

.background {
    background-color: $q-lightgray;
    padding: 1rem 15px;

    .small-container {
        max-width: 1140px;
    }
}

.action-buttons {
    @include media-breakpoint-down(sm) {
        flex-direction: column;

        .ui_button {
            margin: 0.625rem 0;
        }
    }

    @include media-breakpoint-up(sm) {
        flex-direction: column;

        .ui_button {
            margin: 0.625rem 0;
        }
    }

    @include media-breakpoint-up(xl) {
        flex-direction: initial;

        .ui_button {
            margin: 0 1rem;
        }

        .ui_button:first-child {
            margin-left: 0;
        }
    }
}