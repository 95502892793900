.leaders {
    .title-row {
        margin-bottom: 2.5rem;
    }

    .companies {
        justify-content: space-between;
        max-width: 1110px;
        margin: auto;

        .alphabet-filter {
            width: 100%;
            padding: 1.25rem 0;
            border-bottom: 1px solid $q-mediumgray;
            margin-bottom: 1.875rem;

            .navbar-nav {
                li:first-child {
                    padding-left: 0;
                }

                .nav-item {
                    padding: 0 0.75rem;

                    a {
                        color: black;
                        text-decoration: none;
                        transition: 0.3s ease-in-out;
                        position: relative;

                        &::before {
                            content: "";
                            width: 0;
                            height: 3px;
                            margin: 5px 0 0;
                            background: $q-red;
                            position: absolute;
                            bottom: -1.5rem;
                            left: 0;
                            transition-duration: 0.5s;
                            opacity: 0;
                        }

                        &:hover {
                            color: $q-red;

                            &::before {
                                width: 100%;
                                opacity: 1;
                            }
                        }
                    }
                }

                .nav-item.active {
                    a {
                        color: $q-red;

                        &::before {
                            content: "";
                            width: 100%;
                            height: 3px;
                            margin: 5px 0 0;
                            background: $q-red;
                            position: absolute;
                            bottom: -1.5rem;
                            left: 0;
                            transition-duration: 0.5s;
                            opacity: 1;
                        }
                    }
                }

                .nav-item.disabled {
                    a {
                        pointer-events: none;
                        color: $q-gray;
                        cursor: initial;
                    }
                }
            }
        }
    }
}
