.small-container {
    .nav-tabs {
        padding-bottom: 1rem;
        align-items: center;

        li:first-child {
            padding-left: 0;
        }

        .nav-item {
            padding: 0 1.5rem;

            a {
                color: black;
                text-decoration: none;
                transition: 0.3s ease-in-out;
                position: relative;
                font-size: 0.875rem;

                &::before {
                    content: "";
                    width: 0;
                    height: 3px;
                    margin: 5px 0 0;
                    background: black;
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                    transition-duration: 0.5s;
                    opacity: 0;
                }

                &:hover {
                    &::before {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }

            a.active {
                &::before {
                    content: "";
                    width: 100%;
                    height: 3px;
                    margin: 5px 0 0;
                    background: black;
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                    transition-duration: 0.5s;
                    opacity: 1;
                }
            }
        }

        form {
            min-width: 270px;

            @include media-breakpoint-up(xs) {
                margin-right: auto;
            }

            @include media-breakpoint-up(lg) {
                margin-right: unset;
            }

            label {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 1px;
                height: 1px;
            }

            .input-group {
                background-color: white;
                border: 1px solid $q-mediumgray;

                .input-group-prepend {
                    align-items: center;
                    padding: 0 1.25rem;

                    img {
                        height: 22px;
                    }
                }

                input {
                    border: none;
                    padding: 0.85rem 0;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        @include media-breakpoint-up(xs) {
            justify-content: center;

            .nav-item {
                width: 50%;
                text-align: center;
                padding: 1rem 1.5rem;
                margin-bottom: 1rem;

                a:hover,
                a.active {
                    color: $q-red;

                    &::before {
                        display: none;
                    }
                }
            }

            .nav-item:first-child {
                padding-left: 1.5rem;
            }
        }

        @include media-breakpoint-up(lg) {
            justify-content: unset;

            .nav-item {
                text-align: left;
                width: unset;
                padding: 0 1.5rem;
                padding-bottom: 1.75rem;
                margin-bottom: 0;

                a:hover,
                a.active {
                    color: black;

                    &::before {
                        display: initial;
                    }
                }
            }

            .nav-item:first-child {
                padding-left: 0;
            }
        }
    }

    .tab-content {
        .tab-pane {
            padding-top: 1.563rem;

            .no-tiles {
                .q-button {
                    margin: 1.875rem auto;
                }
            }

            .filters {
                padding-left: 1.25rem;
                display: flex;
                align-items: center;
                margin-bottom: 1.563rem;

                .selects {
                    label {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 1px;
                        width: 1px;
                    }

                    select {
                        padding: 1.25rem;
                        font-size: 1rem;
                        border-radius: 0.1rem;
                        resize: none;
                        min-width: 210px;
                        height: unset;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-image: url("/layout/default/dist/gfx/icons/dropdown_arrow.png");
                        background-position: calc(100% - 20px) calc(1em + 10px),
                            calc(100% - 15px) calc(1em + 2px), 100% 0;
                        background-repeat: no-repeat;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }

                @include media-breakpoint-up(xs) {
                    flex-direction: column;
                    padding-right: 1.25rem;

                    .quixi-checkbox {
                        order: 3;
                        margin-top: 1.5rem;
                    }

                    .selects {
                        flex-direction: column;
                        flex-wrap: wrap;

                        select {
                            margin: 0.625rem 0;
                            margin-right: 0;
                        }
                    }
                }

                @include media-breakpoint-up(md) {
                    padding-right: 0;
                    justify-content: flex-end;
                    align-items: flex-start;

                    .quixi-checkbox {
                        .q-checkbox {
                            margin-bottom: 0;
                        }
                    }

                    .selects {
                        flex-direction: initial;

                        select {
                            margin: 0 0;
                            margin-right: 0.625rem;
                            max-width: 31%;
                        }

                        .q-button {
                            margin-left: auto;
                            margin-right: 1rem;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    justify-content: space-between;
                    flex-direction: initial;
                    align-items: center;

                    .quixi-checkbox {
                        order: unset;
                        margin-top: 0;
                    }

                    .selects {
                        flex-wrap: unset;

                        select {
                            max-width: unset;
                        }

                        .q-button {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }

            .ui_tile {
                background-color: white;
                padding: 1.25rem;
                border: 1px solid $q-mediumgray;
                margin-bottom: 1rem;

                .quixi-checkbox {
                    z-index: 10;
                    .q-checkbox {
                        margin-bottom: 0 !important;

                        .checkmark {
                            z-index: 10;
                        }
                    }
                }

                .tile-right {
                    flex-direction: column;
                    justify-content: space-between;

                    @include media-breakpoint-up(xs) {
                        margin-top: 1.25rem;
                    }

                    @include media-breakpoint-up(md) {
                        margin-top: 0;
                    }
                }

                &__image {
                    img {
                        width: 100%;
                        height: auto;
                    }

                    @include media-breakpoint-up(xs) {
                        max-width: unset;
                        margin: 0 auto;
                        margin-left: 0;
                        margin-top: 2.813rem;
                    }

                    @include media-breakpoint-up(md) {
                        max-width: 160px;
                        margin: 0 0;
                        margin-left: 2.813rem;
                        margin-top: 0;
                    }
                }

                &__title {
                    h5 {
                        a {
                            color: black;
                        }
                    }
                    p {
                        color: $q-gray;
                    }
                }

                &__info {
                    .languages {
                        margin-right: 1rem;
                        padding-top: 0.2rem;
                        display: flex;
                        justify-content: center;
                        align-items: baseline;

                        img {
                            margin-right: 0.4rem;
                            height: 16px;
                            width: 16px;
                            border: 1px solid white;
                            border-radius: 100%;
                        }

                        img:last-child {
                            margin-right: 0;
                        }
                    }

                    time {
                        color: $q-gray;
                        font-size: 0.875rem;
                    }
                }

                .tile_desc {
                    justify-content: space-between;

                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                        text-align: center;

                        .ui_tile__title {
                            margin-top: 1rem;
                        }

                        .ui_tile__info {
                            margin: 0 auto !important;
                            flex-direction: column;

                            .languages {
                                margin-right: unset !important;
                                margin-bottom: 0.625rem;
                            }
                        }
                    }
                }

                .tile__buttons {
                    .ui_button {
                        color: black;
                        text-decoration: none;
                    }
                    @include media-breakpoint-up(xs) {
                        flex-direction: column;

                        .ui_button {
                            margin: 0.625rem auto !important;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        flex-direction: initial;

                        .ui_button {
                            margin: 0 1rem !important;
                        }

                        .ui_button:first-child {
                            margin-left: 0 !important;
                        }

                        .ui_button.ml-auto {
                            margin-left: auto !important;
                        }
                    }
                }
            }

            .ui_tile--leader {
                .ui_tile__image {
                    margin-left: 0;
                    border: 1px solid $q-mediumgray;
                    background-color: white;
                    padding: 1.25rem;
                    height: 108px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-height: 55px;
                        width: auto;
                    }

                    @include media-breakpoint-up(xs) {
                        max-width: unset;
                    }

                    @include media-breakpoint-up(lg) {
                        max-width: 160px;
                    }
                }
            }

            .ui_tile--no-photo {
                .col-12 {
                    padding-left: 2.4rem;
                }
            }

            .ui_tile--gallery,
            .ui_tile--video {
                .ui_tile__image {
                    position: relative;

                    .overlay {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: rgba(0, 0, 0, 0.5);
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: inherit;
                        opacity: 1;

                        img {
                            width: initial;
                            height: auto;
                        }
                    }
                }
            }

            .ui_tile:last-child {
                margin-bottom: 0;
            }
        }
    }
}
