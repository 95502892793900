.title-row {
    &--with-icon.company {
        h1 {
            font-size: 2.6rem !important;
        }
    }
}

#key {
    max-width: 50%;
    margin-bottom: 1rem;
}

.upload-more.number {
    margin-bottom: 1rem;
}

.person {
    margin-top: 0.625rem;
    align-items: center;
    background-color: white;
    padding: 1.25rem 1.875rem !important;
    border: 1px solid $q-mediumgray;

    h5 {
        margin: 0 !important;
    }

    .person_actions {
        display: flex;
        align-items: center;
        justify-content: center;

        .no-publish {
            margin-right: 1.875rem !important;

            &::after {
                display: none;
            }

            &::before {
                content: url("/layout/default/dist/gfx/icons/no-publish.png");
                height: 20px;
                margin-right: 0.375rem;
            }
        }

        .publish {
            margin-right: 1.875rem !important;

            &::after {
                display: none;
            }

            &::before {
                content: url("/layout/default/dist/gfx/icons/publish.png");
                height: 28px;
                margin-right: 0.375rem;
            }
        }

        .edit {
            margin-right: 1.875rem;
        }
    }

    @include media-breakpoint-up(xs) {
        flex-direction: column;
        text-align: center;

        .person_actions {
            margin-top: 1rem;
        }
    }

    @include media-breakpoint-up(md) {
        flex-direction: initial;
        text-align: left;

        .person_actions {
            margin-top: 0;
        }
    }
}
