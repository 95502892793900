.subpage.newsletter {
    @include media-breakpoint-up(xs) {
        margin-bottom: 1.875rem;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.newsletter-form {
    background-color: $q-lightgray;
    padding: 1.75rem 15px;
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
        padding: 3.75rem 15px;
        margin-top: 2rem;
    }

    .form-image {
        img {
            float: right;
        }
    }

    .form-desc {
        padding: 0 1.25rem;

        @include media-breakpoint-up(md) {
            padding-left: 2.5rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 0 5.75rem;
        }

        form {
            .form-row {
                margin-bottom: 1.25rem;
                border: none;
                padding: 0;

                .form-group,
                .input-group {
                    margin-bottom: 0 !important;

                    label {
                        width: 100%;
                    }

                    select {
                        width: 100%;
                        font-size: 1rem;
                        padding: 1.25rem;
                        border: none;
                        border-radius: 0;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    .select2-selection__arrow {
                        display: none;
                    }

                    input[type="email"] {
                        font-size: 1rem;
                        padding: 1.25rem;
                        border: none;
                        border-radius: 0;
                        color: black;

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    .quixi-checkbox {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .form-image {
            display: none;
        }

        .form-desc {
            h3 {
                margin-bottom: 1.875rem;
            }

            form {
                text-align: left;
            }
        }
    }
}
