.jobs_main {
    .title-row {
        margin-bottom: 2.5rem;
    }

    .companies {
        justify-content: space-between;
        max-width: 1110px;
        margin: auto;
        .ads-categories {
            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                margin-top: 3.125rem;

                .category {
                    cursor: pointer;

                    .category-icon {
                        border: 1px solid $q-mediumgray;
                        padding: 1rem;
                        height: 100px;
                        width: 100px;
                        border-radius: 100%;

                        img {
                            position: relative;
                            top: 2px;
                        }
                    }

                    p {
                        color: $q-red;
                    }
                }

                @include media-breakpoint-up(xs) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;

                    .category {
                        margin: 0;
                        display: flex;
                        justify-content: center;
                    }
                }

                @include media-breakpoint-up(lg) {
                    display: inline-flex;
                    flex-wrap: unset;

                    .category {
                        margin: 0 2.5rem;
                    }
                }
            }
        }
        .ads-categories {
            margin: 3.125rem 0;
        }

        .recent {
            margin-bottom: 3.125rem;
        }
    }
}
