.news_feed, .video {
    position: relative;

    .more-news {
        margin: 2.5rem 0;

        .q-button {
            margin: 0 auto;
        }
    }
}

.presskit_news.news_feed {
    @include media-breakpoint-up(md) {
        padding: 0;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 15px;
    }
}