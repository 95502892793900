.exchange_main {
    .title-row {
        @include media-breakpoint-up(xs) {
            h1 {
                font-size: 2rem;
            }
        }

        @include media-breakpoint-up(md) {
            h1 {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
            }
        }

        @media only screen and (min-width: 1400px) {
            h1 {
                font-size: 3.125rem;
            }
        }

        p {
            display: inline;
            color: $q-gray;
            margin-right: 1.25rem;
        }
    }

    .company-desc {
        padding: 0;

        .desc {
            .categories {
                margin-bottom: 2.5rem;
            }

            span {
                font-weight: 700;
                font-size: 1.125rem;
                display: block;
                margin-bottom: 1rem;
            }

            ul {
                li {
                    p {
                        margin-bottom: 0;
                    }
                }
            }

            p {
                font-size: 1rem;
                margin-bottom: 0.5rem;

                a {
                    color: $q-red;
                }
            }
        }
    }
}
