.mobile-log, .mobile-logged-menu {
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 1045;
        display: none;
    }

    .login-select {
        z-index: 1046;
        background-color: white;
        position: fixed;
        width: 100%;
        top: 0;
        padding: 7.25rem 0;
        display: none;

        .search-close {
            position: absolute;
            top: 1.875rem;
            right: 1.875rem;
            background-color: transparent;
            border: none;
            font-size: 0.875rem;
            cursor: pointer;
            display: flex;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::after {
                content: url('/layout/default/dist/gfx/icons/close.png');
                margin-left: 0.625rem;
            }
        }

        .item {
            a {
                color: black;
                font-size: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 2rem;
            }
        }

        .dropdown-menu {
            position: static;
            display: block;
            border: none;
            width: 80%;
            margin: auto;
            float: none;

            .dropdown-item {
                font-size: 1.5rem;
                text-decoration: none;
                transition: 0.2s ease;
                display: flex;
                justify-content: center;
                align-items: center;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    background-color: white;
                }
            }

            .dropdown-item.presskit {
                &::after {
                    content: url('/layout/default/dist/gfx/icons/presskit-icon.png');
                    margin-left: 0.5rem;
                }
            }

            .log-out {
                color: black;

                &::before {
                    content: url('/layout/default/dist/gfx/icons/logout.png');
                    margin-right: 0.5rem;
                    position: relative;
                    top: 3px;
                }
            }
        }
    }
}