html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: Helvetica, sans-serif;
    overflow-x: hidden;
    padding-top: 136px;

    /* FONT SIZES */

    h1 {
        font-size: 2.5rem;

        @include media-breakpoint-up(lg) {
            font-size: 3.125rem;
        }
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    h5 {
        font-size: 1.25rem;
    }

    .subpage {
        margin-top: 1.875rem;

        .page-info {
            margin-bottom: 2rem;

            a {
                color: black;
            }

            span {
                margin: 0 0.625rem;
            }

            a:last-child {
                color: $q-gray;
            }
        }
    }

    @media only screen and (min-width: 1588px) {
        .container.c-small {
            max-width: 1140px;
        }
    }

    @media only screen and (min-width: 576px) {
        .swiper-container {
            width: 540px;
        }
    }

    @media only screen and (min-width: 768px) {
        .swiper-container {
            width: 720px;
        }
    }

    @media only screen and (min-width: 992px) {
        .swiper-container {
            width: 960px;
        }
    }

    @media only screen and (min-width: 1170px) {
        .swiper-container {
            width: 1140px;
        }
    }
}
