.ajax-filters {
    display: block;
    position: fixed;
    left: -370px;
    z-index: 1020;
    width: 420px;
    min-height: 518px;
    transition: all 0.5s ease;
    overflow: hidden;
    top: 0;
    transform: translate(0, 31.9%);
    background-color: $q-lightgray;

    .row {
        margin-left: 0;
        margin-right: 0;

        .categories {
            padding: 3.438rem;
            padding-right: 1.5rem;

            h4 {
                margin-bottom: 1.875rem;
            }

            .cat-column {
                padding-left: 0;

                @include media-breakpoint-up(xl) {
                    padding-left: 15px;
                }
            }

            .cat-column:first-child {
                padding-left: 0;
            }

            ul {
                list-style: none;
                padding: 0;
                max-height: 1px;
                transition: height 0.3s ease-in-out;
                padding-left: 40px;
                margin-left: -40px;

                li {
                    margin-bottom: 1rem;
                    cursor: pointer;
                    position: relative;
                    transition: 0.2s ease-in-out;
                    font-size: 0.938rem;

                    @include media-breakpoint-up(md) {
                        font-size: 1.25rem;
                    }

                    @include media-breakpoint-up(xl) {
                        font-size: 0.938rem;
                    }
                }

                li.active {
                    color: $q-red;

                    &::before {
                        content: url("/layout/default/dist/gfx/icons/mark.png");
                        position: absolute;
                        left: -1.875rem;
                    }
                }
            }

            button {
                color: $q-gray;
                margin-bottom: 0;
                margin-top: 1.5rem;
                background-color: transparent;
                border: none;
                cursor: pointer;
                padding: 0;
                font-size: 0.938rem;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        .toggle {
            cursor: pointer;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $q-gray;
            width: 100%;
            padding: 10.25rem 0;
            transition: 0.5s ease;

            @include media-breakpoint-up(xl) {
                max-width: 50px;
                margin-left: auto;
                min-height: 518px;
            }

            span {
                writing-mode: tb-rl;
                transform: rotate(180deg);
                white-space: nowrap;
                display: block;

                &::before {
                    content: url("/layout/default/dist/gfx/icons/side-filter-arrows.png");
                    margin-bottom: 1.25rem;
                    transform: rotate(180deg);
                    display: inline-block;
                    transition: 0.5s ease;
                }
            }
        }
    }

    @media only screen and (max-width: 1170px) {
        display: flex;
        position: initial;
        transform: none;
        margin-bottom: 2rem;
        width: 100%;
        min-height: unset;

        .row {
            width: 100%;
        }

        .categories {
            order: 2;
            display: none;
        }

        .toggle {
            padding: 0.75rem 0 !important;

            span {
                transform: none !important;
                writing-mode: unset !important;

                &::before {
                    margin-bottom: 0 !important;
                    transform: rotate(90deg) !important;
                    margin-right: 1rem;
                }
            }
        }
    }
}

.ajax-filters.sticky {
    left: -430px;
    opacity: 0;
}

.ajax-filters.show {
    left: 0;
    transform: translateX(0, -50%);

    .categories {
        ul {
            overflow: hidden;
            max-height: 280px;
        }
    }

    .toggle {
        background-color: $q-lightgray;
        color: $q-gray;

        span {
            &::before {
                transform: rotate(0deg);
                filter: brightness(0.5);
            }

            @include media-breakpoint-down(lg) {
                &::before {
                    transform: rotate(270deg) translateY(5px) !important;
                    filter: brightness(0.5);
                }
            }
        }
    }
}

.presskit_news {
    .ajax-filters {
        top: 20%;
    }
}
