.sort-payments {
    label {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 1px;
    }

    select {
        padding: 0.85rem;
        font-size: 1rem;
        border-radius: 0.10rem;
        resize: none;
        min-width: 210px;
        height: unset !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('/layout/default/dist/gfx/icons/dropdown_arrow.png');
        background-position: calc(100% - 20px) calc(1em + 5px), calc(100% - 15px) calc(1em + 2px), 100% 0;
        background-repeat: no-repeat;
        margin-right: 0.625rem;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .input-group {
          @include media-breakpoint-up(lg) {
              min-width: 350px;
          }
      }
}

.tab-content {
    .filters {
        .documents-desc {

            @media only screen and (max-width: 768px) {
                display: none !important;
            }

            width: 50%;
            justify-content: space-between;

            p {
                font-size: 0.875rem;
            }
        }
    }
    .ui_tile--document {
        .col-12 {
            padding-left: 2.4rem;

            .ui_tile__title {
                h5 {
                    margin: 0;
                }
            }

            .ui_tile__info {
                width: 50%;
                justify-content: space-between;

                @include media-breakpoint-up(xs) {
                    justify-content: center;
                    align-items: center;
                }

                @include media-breakpoint-up(md) {
                    justify-content: space-between;
                    align-items: unset;
                }

                time, p, a {
                    margin: 0;
                    color: black;
                    font-size: 0.875rem;
                    display: flex;
                    align-items: center;
                }

                p {
                    color: $q-red;
                }
            }
        }
    }
}