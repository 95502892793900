.company_about {
    .company-desc {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        padding-bottom: 3.125rem;
        padding: 0 15px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }

        .desc {
            .main-image {
                position: relative;

                .overlay-download {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    background-color: rgba(0, 0, 0, 0.5);
                    width: 100%;
                    height: calc(100% - 1.3rem);
                    padding: inherit;
                    opacity: 0;
                    transition: 0.3s ease-in-out;
                    z-index: 1;

                    .download {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        right: 0;
                        width: 48px;
                        margin: auto;
                    }

                    .social-upload {
                        display: flex;
                        max-width: 20%;
                        margin-left: auto;
                        margin-right: 1rem;
                        margin-top: 2rem;
                        justify-content: space-evenly;

                        a {
                            img {
                                width: unset;
                                height: unset;
                            }
                        }
                    }
                }
                .overlay-download.selected,
                .overlay-download:hover {
                    opacity: 1;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 1.25rem;
                }
                p {
                    color: $q-gray;
                    font-size: 0.85rem;
                }
            }

            .categories {
                color: $q-red;
                font-size: 1.125rem;
                margin: 0;
            }

            h4 {
                margin-bottom: 1.25rem;
            }

            p {
                img {
                    margin: 1.25rem 0;
                }
            }
        }

        .people {
            h4 {
                margin-bottom: 1.875rem;
            }

            .person {
                border: 1px solid $q-mediumgray;
                margin-bottom: 1.25rem;
                padding: 0 !important;
                margin-left: 0;
                margin-right: 0;
                max-height: 174px;

                .person-image {
                    padding: 0;
                    max-width: 260px;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        height: auto;
                    }
                }

                .person-desc {
                    padding: 1.875rem 15px;
                    padding-left: 30px;

                    p {
                        color: $q-gray;
                        margin-bottom: 0;
                    }

                    a {
                        color: $q-red;
                        margin-top: 1rem;
                        display: block;
                    }
                }

                @include media-breakpoint-down(md) {
                    max-height: unset;

                    .person-image {
                        max-width: unset;
                    }
                }
            }

            .person:last-child {
                margin-bottom: 0;
            }
        }
    }
}
