/* Styl buttona */

.q-button {
    color: white;
    border: none;
    background-color: $q-red;
    display: flex;
    align-items: center;
    white-space: nowrap;
    width: fit-content;
    width: -moz-fit-content;
    padding: 1.25rem 2.5rem;
    cursor: pointer;
    border-radius: 0.2rem;
    transition: 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
        background-color: $q-darkred;
        color: white;
        text-decoration: none;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &::before {
        margin-right: 1rem;
    }

    &.news,
    &.leaders,
    &.leaders_team,
    &.exchange,
    &.jobs,
    &.presskit_news,
    &.presskit_gallery,
    &.presskit_video,
    &.presskit_services,
    &.presskit_company,
    &.presskit_company_team,
    &.presskit_team,
    &.presskit_contact {
        padding: 0.8rem 2.5rem;
        &::before {
            filter: brightness(0) invert(1);
            background-size: contain;
            background-repeat: no-repeat;
            width: 50px;
            height: 40px;
            content: "";
        }
    }

    &.news {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/news.png");
        }
    }

    &.leaders {
        padding: 0.7rem 2.5rem;

        &::before {
            background-image: url("/layout/default/dist/gfx/icons/leaders.png");
        }
    }

    &.leaders_team {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/leaders_team.png");
        }
    }

    &.exchange {
        padding: 0.5rem 2.5rem;

        &::before {
            background-image: url("/layout/default/dist/gfx/icons/exchange.png");
        }
    }

    &.jobs {
        padding: 0.7rem 2.5rem;

        &::before {
            background-image: url("/layout/default/dist/gfx/icons/jobs.png");
        }
    }

    &.presskit_news {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_news.png");
        }
    }

    &.presskit_gallery {
        padding: 0.5rem 2.5rem;

        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_gallery.png");
        }
    }

    &.presskit_video {
        padding: 0.5rem 2.5rem;

        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_video.png");
        }
    }

    &.presskit_services {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_services.png");
        }
    }

    &.presskit_company {
        padding: 0.7rem 2.5rem;

        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_company.png");
        }
    }

    &.presskit_company_team {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_company_team.png");
        }
    }

    &.presskit_team {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_team.png");
        }
    }

    &.presskit_contact {
        &::before {
            background-image: url("/layout/default/dist/gfx/icons/presskit_contact.png");
        }
    }
}

/* Button szary */

.q-button-gray {
    color: black;
    border: none;
    background-color: $q-mediumgray;
    padding: 1.25rem 2.5rem;
    cursor: pointer;
    border-radius: 0.2rem;
    transition: 0.3s ease-in-out;
    text-decoration: none;

    &:hover {
        background-color: $q-gray;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}
