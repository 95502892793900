.publications {
    .title {
        @include media-breakpoint-up(xs) {
            margin-bottom: 1.5rem;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 2.5rem;
        }
    }

    .intro-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 2.5rem;
        text-align: left;

        .patrons-logotypes {
            display: flex;
            align-items: center;
            max-width: 100%;
            flex-wrap: wrap;
            margin-bottom: 1.25rem;

            img {
                margin-right: 1.875rem;
                max-width: 90px;
                max-height: 50px;

                @include media-breakpoint-up(lg) {
                    max-width: 150px;
                }

                @include media-breakpoint-up(xl) {
                    max-width: 190px;
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        h1 {
            margin-bottom: 2.5rem;
            font-size: 2.5rem;

            @include media-breakpoint-up(md) {
                font-size: 3.125rem;
            }
        }

        p {
            font-size: 1.125rem;
            margin-bottom: 2.5rem;
        }

        .q-button {
            padding: 1rem 2.5rem;
        }
    }

    .intro-image {
        img {
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                max-width: 460px;
            }
        }
    }
}

.intro_banner-publications {
    margin-top: 0;

    @include media-breakpoint-up(md) {
        margin-top: 2rem;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 5rem;
    }
}

.more-about.publication {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }

    p {
        font-size: 1.25rem;
    }
}

.publication-tiles {
    background-color: $q-lightgray;
    padding: 3.25rem 15px;

    @include media-breakpoint-up(md) {
        padding: 6.25rem 15px;
    }

    .row {
        max-width: 1140px;
        margin: 0 auto;
        justify-content: space-between;

        @include media-breakpoint-up(xs) {
            .tile:last-child {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(md) {
            .tile:last-child {
                margin-bottom: 3.125rem;
            }
        }

        .tile {
            margin-bottom: 3.125rem;
            padding: 0;

            @include media-breakpoint-up(md) {
                margin-bottom: 1.25rem;
                padding: 0 15px;
            }

            .tile__desc {
                h3 {
                    margin-bottom: 1.25rem;
                }

                p {
                    color: black;
                }

                a {
                    color: $q-red;
                    &.q-button {
                        color: white;
                    }
                }
            }
        }
    }
}

.publications_office {
    padding: 15px 15px;

    @include media-breakpoint-up(md) {
        padding: 5rem 15px;
        padding-bottom: 0;
    }

    .intro-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 2.5rem;

        @include media-breakpoint-up(md) {
            padding-right: 35px;
            margin-bottom: 0;
        }

        h2 {
            margin-bottom: 2.5rem;
        }

        p {
            font-size: 1.125rem;
            margin-bottom: 1.875rem;
        }

        .q-button {
            padding: 1rem 2.5rem;
        }
    }

    .info {
        .info-cat {
            margin-bottom: 1.5rem;
            display: flex;
            align-items: center;

            @include media-breakpoint-up(md) {
                margin-bottom: 2.5rem;
            }

            figure {
                margin: 0;
                margin-right: 1rem;
                max-width: 50px;

                img {
                    width: 100%;
                }
            }

            article {
                h2 {
                    font-size: 2rem;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        .info-cat:last-child {
            margin-bottom: 0;
        }
    }
}

.banner-publications {
    margin-top: 3.75rem;
}

.patrons {
    padding: 1.5rem 15px;

    @include media-breakpoint-up(md) {
        padding: 3.125rem 15px;
    }

    .title {
        margin-bottom: 3.125rem;
    }

    .logotype {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.875rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        img {
            max-width: 100%;
            max-height: 80px;

            @include media-breakpoint-up(lg) {
                max-height: 100%;
            }
        }
    }
}

.coordinators {
    background-color: $q-lightgray;
    padding: 2rem 15px;

    @include media-breakpoint-up(md) {
        padding: 5rem 15px;
    }

    .row {
        max-width: 1140px;
        margin: 0 auto;
        justify-content: space-between;

        .title {
            margin-bottom: 1.875rem;

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }

        .tile--horizontal {
            .tile__image {
                img {
                    max-height: unset;
                }
            }

            .tile__desc {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h5 {
                    display: block;
                    max-width: unset;
                    height: unset;
                    -webkit-line-clamp: unset;
                    -webkit-box-orient: unset;
                    overflow: auto;
                    text-overflow: unset;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.contact-us.publication {
    margin-top: 5rem;
    margin-bottom: 5rem;

    h2 {
        color: black;
    }

    .contact-info {
        .info {
            a {
                color: $q-red;
            }
        }
    }
}
