$q-lightgray: #f2f4f5;
$q-mediumgray: #d7d8d8;
$q-gray: #999999;
$q-red: #cc0c2f;
$q-darkred: #9f0a25;
$q-darkblue: #00174f;
$q-black: #000000;

.gray {
    color: $q-gray;
}

.q-red {
    color: $q-red;
}

.q-black {
    color: $q-black;
}

a.q-black {
    transition: 0.2s ease;

    &:hover {
        color: $q-red;
        text-decoration: none;
    }
}
