.ajax-container {
    width: 100%;
    position: relative;
}

.mfp-wrap {
    .mfp-content {
        iframe {
            width: 90vw;
            height: 90vh;
            display: block;
            margin: 0 auto;
            background-color: gray;
        }
        .mfp-close {
            color: white;
        }
    }
}
