.title-row {
    &--with-icon {
        display: flex;
        align-items: center;

        &::before {
            content: url("/layout/default/dist/gfx/icons/news.png");
            margin-right: 1.875rem;
        }

        &.news::before {
            content: url("/layout/default/dist/gfx/icons/news.png");
        }

        &.leaders::before {
            content: url("/layout/default/dist/gfx/icons/leaders.png");
        }

        &.leaders_team::before {
            content: url("/layout/default/dist/gfx/icons/leaders_team.png");
        }

        &.exchange::before {
            content: url("/layout/default/dist/gfx/icons/exchange.png");
        }

        &.jobs::before {
            content: url("/layout/default/dist/gfx/icons/jobs.png");
        }

        &.presskit_news::before {
            content: url("/layout/default/dist/gfx/icons/presskit_news.png");
        }

        &.presskit_gallery::before {
            content: url("/layout/default/dist/gfx/icons/presskit_gallery.png");
        }

        &.presskit_video::before {
            content: url("/layout/default/dist/gfx/icons/presskit_video.png");
        }

        &.presskit_services::before {
            content: url("/layout/default/dist/gfx/icons/presskit_services.png");
        }

        &.presskit_company::before {
            content: url("/layout/default/dist/gfx/icons/presskit_company.png");
        }

        &.presskit_company_team::before {
            content: url("/layout/default/dist/gfx/icons/presskit_company_team.png");
        }

        &.presskit_team::before {
            content: url("/layout/default/dist/gfx/icons/presskit_team.png");
        }

        &.presskit_contact::before {
            content: url("/layout/default/dist/gfx/icons/presskit_contact.png");
        }
    }
}

.language-select {
    display: flex;
    align-items: center;

    .language-dropdown {
        padding: 1rem 1.25rem;
        border: 1px solid $q-mediumgray;
        background-color: white;
        cursor: pointer;
        min-width: 227px;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        span {
            width: 100%;
            display: flex;
            align-items: center;
            &::before {
                margin-right: 0.625rem;
            }

            &::after {
                content: url("/layout/default/dist/gfx/icons/dropdown_arrow.png");
                flex: 1;
                text-align: right;
            }
        }
    }

    .dropdown-menu {
        border-top: none;
        margin-top: 0;

        @media screen and (max-height: 663px) {
            transform: translate3d(0px, 58px, 0px) !important;
        }

        .dropdown-item {
            padding: 0.5rem 1.25rem;
            min-width: 226px;
            display: flex;
            justify-content: space-between;

            .language_actions {
                display: flex;
                align-items: center;

                a:first-child {
                    margin-right: 0.425rem;

                    img {
                        max-height: 22px;
                    }
                }
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        color: black;
        text-decoration: none;

        &::before {
            margin-right: 0.625rem;
        }
    }

    .pl {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/pl.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .en {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/en.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .de {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/de.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .it {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/it.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .cz {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/cz.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .es {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/es.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .fr {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/fr.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    .ru {
        &::before {
            content: "";
            background-image: url("/layout/default/dist/gfx/icons/ru.svg");
            background-size: cover;
            height: 16px;
            width: 16px;
            border: 1px solid white;
            border-radius: 100%;
        }
    }

    @include media-breakpoint-up(xs) {
        flex-direction: column;
        text-align: center;
    }
    @include media-breakpoint-up(md) {
        flex-direction: initial;
        text-align: left;
    }
}

.form-background {
    background-color: $q-lightgray;
    padding: 3.125rem 15px;
    margin-top: 2.5rem;

    .form-container {
        max-width: 1140px;
    }
}

.form-background.login {
    margin-top: 0;
}

form {
    .form-row {
        padding: 1.875rem 0;
        border-bottom: 1px solid $q-mediumgray;
        margin-bottom: 1.875rem;

        h5 {
            width: 100%;
            margin-bottom: 1.875rem;
        }

        small {
            font-size: 0.875rem;
            margin-bottom: 1rem;
        }

        .small-text {
            width: 100%;
            font-size: 0.875rem;
            margin-bottom: 1rem;
        }

        .upload-more {
            margin: 1rem 0;

            button {
                color: $q-red;
                cursor: pointer;
                font-size: 0.875rem;
                padding: 0;
                border: none;
                background-color: transparent;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        .upload_image_subtitle {
            position: relative;

            button {
                cursor: pointer;
                font-size: 0.875rem;
                padding: 0;
                border: none;
                background-color: transparent;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            .del-more {
                height: unset !important;
                margin-top: -0.3rem !important;
            }
        }

        .label {
            margin-bottom: 1rem;
        }

        .images {
            .form-row {
                padding: 0;
                margin: 0;
                border-bottom: none;
            }
        }

        .form-group {
            position: relative;

            .del-more {
                position: absolute;
                right: 0.5rem;
                top: 0;
                bottom: 0;
                height: fit-content;
                margin-top: 0.5rem;
                font-size: 2rem;
                color: $q-red;
                z-index: 10;
            }

            .upload_image {
                .upload_tile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 1.25rem 1.875rem;
                    background-color: white;
                    border: 1px solid $q-mediumgray;
                    cursor: pointer;
                    min-height: 124px;
                    max-height: 125px;
                    max-width: 175px;
                    position: relative;

                    @include media-breakpoint-up(xs) {
                        margin: auto;
                    }

                    @include media-breakpoint-up(md) {
                        margin: unset;
                    }

                    .loader_bg {
                        background-color: white;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .tile_loader {
                            border: 16px solid #f3f3f3;
                            border-top: 16px solid $q-red;
                            border-radius: 50%;
                            width: 60px;
                            height: 60px;
                            animation: loading 2s linear infinite;
                        }

                        @keyframes loading {
                            0% {
                                transform: rotate(0deg);
                            }
                            100% {
                                transform: rotate(360deg);
                            }
                        }
                    }

                    .miniature {
                        max-width: 100%;
                        max-height: 100%;

                        img {
                            max-width: 100%;
                            max-height: 80px;
                        }
                    }

                    p {
                        color: $q-red;
                        font-size: 0.875rem;
                        margin: 0;
                        margin-top: 1rem;
                    }
                }

                input {
                    display: none;
                }
            }

            .upload_image.gray {
                max-width: 255px;
                .upload_tile {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 1.25rem 1.875rem;
                    background-color: $q-lightgray;
                    border: 1px solid $q-mediumgray;
                    cursor: pointer;
                    min-height: 171px;
                    position: relative;

                    .miniature {
                        max-width: 100%;
                        max-height: 100%;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }

                input {
                    display: none;
                }
            }

            input,
            select,
            textarea,
            .select2-selection {
                padding: 1.25rem;
                font-size: 1rem;
                border-radius: 0.1rem;
                resize: none;

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }

            select,
            .select2-selection {
                height: unset !important;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("/layout/default/dist/gfx/icons/dropdown_arrow.png");
                background-position: calc(100% - 20px) calc(1em + 10px),
                    calc(100% - 15px) calc(1em + 2px), 100% 0;
                background-repeat: no-repeat;
            }

            .select2-selection {
                background-position-y: calc(50%), calc(1em + 2px), 0px;
                &__choice {
                    border-radius: 0.1rem;
                    background-color: #ffffff;
                }
            }

            label {
                font-size: 0.875rem;
                margin: 0;
                &.required {
                    &::after {
                        content: " *";
                    }
                }
            }

            .ck .ck-toolbar {
                width: 100%;
            }

            .phone,
            .email {
                margin-bottom: 1rem;
            }
        }
    }

    .checkbox-only {
        padding: 0;

        h5 {
            margin-top: 1.875rem;
        }

        .q-checkbox {
            font-size: 1rem !important;
        }

        .form-row {
            border: none;
            padding: 0;
        }
    }

    .confirm {
        border: none;
        padding-bottom: 0;
    }

    .finish {
        align-items: center;
        justify-content: flex-end;
        border-bottom: none;
        margin-bottom: 0;

        p {
            flex: 1;
        }

        button {
            display: flex;
            align-items: center;

            &::before {
                margin-right: 1rem;
                margin-top: 5px;
            }
        }

        .form-group {
            margin: 0;
        }

        .q-button-gray {
            padding: 0.8rem 2.5rem;
            display: flex;
            align-items: center;
            min-height: 70px;

            &::before {
                background-image: url("/layout/default/dist/gfx/icons/preview.png");
                background-size: contain;
                background-repeat: no-repeat;
                width: 26px;
                height: 16px;
                content: "";
                margin-right: 1rem;
            }
        }

        .q-button,
        .q-button-none {
            padding: 0.8rem 2.5rem;

            &::before {
                filter: brightness(0) invert(1);
                background-image: url("/layout/default/dist/gfx/icons/news.png");
                background-size: contain;
                background-repeat: no-repeat;
                width: 50px;
                height: 40px;
                content: "";
            }

            &.news {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/news.png");
                }
            }

            &.leaders {
                padding: 0.7rem 2.5rem;

                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/leaders.png");
                }
            }

            &.leaders_team {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/leaders_team.png");
                }
            }

            &.exchange {
                padding: 0.5rem 2.5rem;

                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/exchange.png");
                }
            }

            &.jobs {
                padding: 0.7rem 2.5rem;

                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/jobs.png");
                }
            }

            &.presskit_news {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_news.png");
                }
            }

            &.presskit_gallery {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_gallery.png");
                }
            }

            &.presskit_video {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_video.png");
                }
            }

            &.presskit_services {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_services.png");
                }
            }

            &.presskit_company {
                padding: 0.7rem 2.5rem;

                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_company.png");
                }
            }

            &.presskit_company_team {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_company_team.png");
                }
            }

            &.presskit_team {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_team.png");
                }
            }

            &.presskit_contact {
                &::before {
                    background-image: url("/layout/default/dist/gfx/icons/presskit_contact.png");
                }
            }
        }

        .q-button-none {
            min-height: 70px;

            &::before {
                display: none;
            }
        }

        a {
            color: black;
            text-decoration: none;
        }

        @include media-breakpoint-up(xs) {
            justify-content: center;

            a,
            button {
                margin: 1rem 0;
            }

            a {
                width: 100%;
                text-align: center;
            }
        }

        @include media-breakpoint-up(md) {
            justify-content: left;

            a,
            button {
                margin: 0 0.625rem;
            }

            a {
                width: unset;
                text-align: left;
            }
        }
    }
}

.checkbox {
    label {
        display: inline-block;

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: baseline;
        }

        a {
            color: $q-red;
        }

        input {
            appearance: none;
            -webkit-appearance: none;

            &::before {
                content: "";
                position: relative;
                top: 5px;
                display: inline-block;
                height: 22px;
                width: 22px;
                border: 2px solid $q-mediumgray;
                background-color: white;
            }
        }

        input:checked {
            &::before {
                background-image: url("/layout/default/dist/gfx/icons/tick.svg");
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
}

.quixi-checkbox {
    /* Customize the label (the container) */
    .q-checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        a {
            color: $q-red;
        }
    }

    /* Hide the browser's default checkbox */
    .q-checkbox input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        border: 2px solid $q-mediumgray;
        background-color: white;
    }

    /* When the checkbox is checked, add a blue background */
    .q-checkbox input:checked ~ .checkmark {
        background-image: url("/layout/default/dist/gfx/icons/tick.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .q-checkbox input:checked ~ .checkmark:after {
        display: block;
    }
}

//Walidacja

.invalid {
    background-color: rgb(255, 210, 210) !important;
    border-color: $q-red !important;
    color: $q-red;
}

input[type="checkbox"]:invalid {
    background-color: transparent;
    border-color: transparent;

    &::before {
        background-color: rgb(255, 210, 210) !important;
        border-color: $q-red !important;
    }
}

input[type="file"]:invalid ~ .upload_tile {
    background-color: rgb(255, 210, 210) !important;
    border-color: $q-red !important;
}
