.search_results {
    .page-title {
        margin-bottom: 2.5rem;
    }

    .search-feed {
        .result {
            padding-top: 1.875rem;
            padding-bottom: 1.25rem;
            border-bottom: 1px solid $q-mediumgray;

            a {
                color: black;
                text-decoration: none;
                p {
                    margin: 0;
                    color: $q-gray;
                }

                &:hover {
                    h5 {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}