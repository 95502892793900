.pages {
    margin-top: 2.5rem;
    margin-bottom: 1.875rem;

    .pagination {
        .page-item {
            margin: 0 0.5rem;

            .page-link {
                border: none;
                color: $q-gray;
                background-color: transparent;
                padding: 0.5rem 1rem;
                transition: 0.3s ease-in-out;

                &::before {
                    content: "";
                    width: 0;
                    height: 3px;
                    margin: 5px 0 0;
                    background: $q-red;
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                    transition-duration: 0.5s;
                    opacity: 0;
                }

                &:hover {
                    color: $q-red;

                    &::before {
                        width: 100%;
                        opacity: 1;
                    }
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }

        .page-item.active {
            a {
                color: $q-red;
                background-color: initial;

                &::before {
                    content: "";
                    width: 100%;
                    height: 3px;
                    margin: 5px 0 0;
                    background: $q-red;
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                    transition-duration: 0.5s;
                    opacity: 1;
                }
            }
        }
    }
}
