#settings {
    padding: 1.875rem 0;

    .card {
        border: 1px solid $q-mediumgray;
        margin-bottom: 0.625rem;
        .card-header {
            border: none;
            background-color: white;
            padding: 0;

            .toggle-btn {
                width: 100%;

                span {
                    flex: 1;
                    text-align: right;

                    &::after {
                        content: url('/layout/default/dist/gfx/icons/dropdown_arrow.png');
                        margin-left: 0.625rem;
                        transition: 0.2s ease-in-out;
                        display: inline-block;
                    }
                }

                @include media-breakpoint-down(xs) {
                    font-size: 1rem;
                    padding: 1.25rem 1rem;

                    span {
                        color: white;
                        font-size: 1px;
                    }
                }

                @include media-breakpoint-up(sm) {
                    font-size: 1rem;
                    padding: 1.25rem 1rem;

                    span {
                        color: white;
                        font-size: 1px;
                    }
                }

                @include media-breakpoint-up(md) {
                    font-size: 1.25rem !important;
                    padding: 1.25rem 1.875rem !important;

                    span {
                        color: black;
                        font-size: 0.875rem !important;
                    }
                }
            }

            .toggle-btn.toggled {
                span {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .collapse {
            .card-body {
                form {
                    .form-row {
                        border-bottom: none;
                        padding-bottom: 0;
                        border-top: 1px solid $q-mediumgray;

                        .form-group {
                            .form-row {
                                border: none;
                                padding: 0;
                            }

                            select {
                                height: unset;
                            }
                        }

                        .half-input {
                            input {

                                @include media-breakpoint-down(xs) {
                                    max-width: unset;
                                }

                                @include media-breakpoint-up(sm) {
                                    max-width: unset;
                                }

                                @include media-breakpoint-up(md) {
                                    max-width: 50% !important;
                                }
                            }
                        }
                    }

                    .checkbox-only {
                        padding-top: 1.875rem;
                        margin: 0;
                    }

                    .submit {
                        .q-button {
                            margin-left: 1.25rem;
                        }
                    }
                }
            }
        }
    }

    .card:last-child {
        margin-bottom: 0;
    }
}