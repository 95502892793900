.banner {
    margin-top: 2rem;

    @include media-breakpoint-up(xl) {
        margin-top: 5rem;
    }

    .banner-image {
        img {
            width: 100%;
            max-height: 650px;

            @media only screen and (min-width: 2000px) {
                max-height: 800px;
            }
        }
    }
}

.banner.banner-split {
    padding: 1.875rem 15px;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-color: $q-darkblue;
    color: white;

    @include media-breakpoint-up(md) {
        background-size: cover;
        background-blend-mode: unset;
        background-position-x: 70%;
    }

    @include media-breakpoint-up(lg) {
        background-position-x: 70%;
    }

    @media only screen and (min-width: 1900px) {
        background-size: contain;
        background-position-x: unset;
    }

    @media only screen and (min-width: 2000px) {
        background-position-x: 30%;
    }

    .row {
        max-width: 1140px;
        margin: 0 auto;

        .desc {
            @include media-breakpoint-up(xs) {
                padding: 1.125rem 0;
            }

            @include media-breakpoint-up(md) {
                padding: 4.375rem 0;
                padding-left: 15px;

                .q-button {
                    margin-right: auto;
                }
            }

            h2 {
                margin-bottom: 2.25rem;
            }
        }
    }

    &--adverts {
        background-image: url("/layout/default/dist/gfx/banner_2.png") !important;
    }

    &--presskit {
        background-image: url("/layout/default/dist/gfx/folder_white.png") !important;
    }

    &--exchange {
        background-image: url("/layout/default/dist/gfx/exchange_banner.png") !important;
    }

    &--leaders {
        background-image: url("/layout/default/dist/gfx/case_white.png") !important;
    }
}

.banner-split.bg-grey {
    background-color: $q-lightgray;
    background-image: none;
    margin: 0;
    color: black;

    @include media-breakpoint-up(md) {
        background-image: url("/layout/default/dist/gfx/folder.png");
        background-position-x: 55% !important;
    }

    @media only screen and (min-width: 2000px) {
        background-position-x: 45%;
    }

    .desc {
        @include media-breakpoint-up(md) {
            padding: 8.375rem 0px !important;
        }
    }
}
