.about-us {
    .title {
        @include media-breakpoint-up(xs) {
            margin-bottom: 2.625rem;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 5.625rem;
        }
    }

    .intro-text {
        h2 {
            margin-bottom: 1.875rem;
        }

        h4 {
            margin-bottom: 2.875rem;
        }

        .q-button {
            padding: 1rem 4.375rem;
        }

        p {
            font-size: 1.125rem;
        }
    }

    .intro-image {
        img {
            max-width: 100%;
        }
    }
}

.more-about {
    margin-top: 5rem;
    margin-bottom: 5rem;

    .bg-blue {
        background-color: #0094dc;
        color: white;
        padding: 4.688rem 5.938rem;
    }
}

.about-us-list {
    margin-top: 4.688rem;
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        overflow: visible;
    }

    &::after {
        content: "";
        background-color: $q-lightgray;
        position: absolute;
        z-index: -1;
        top: 0;
        left: -35%;
        width: 170%;
        height: 100%;
    }

    h2 {
        margin-bottom: 1.875rem;
    }

    h4 {
        margin-bottom: 1.5rem;
    }

    ul {
        text-align: left;

        @include media-breakpoint-up(xs) {
            padding-left: 60px;
        }

        @include media-breakpoint-up(md) {
            padding-left: 15px;
        }
    }

    .big-icons-section {
        display: flex;
        flex-wrap: wrap;
        margin: 6.25rem 0;
        margin-bottom: 0;

        .offer {
            display: flex;
            align-items: center;
            margin-bottom: 5.313rem;

            .icon {
                min-width: 200px;
            }

            .desc {
                padding: 3.125rem;
            }

            @include media-breakpoint-up(xs) {
                flex-direction: column;

                .icon {
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                flex-direction: initial;

                .icon {
                    img {
                        display: block;
                        margin: unset;
                    }
                }
            }
        }
    }
}

.contact-us {
    margin-top: 2rem;

    h2,
    a {
        color: #0094dc;
    }

    .contact-info {
        display: flex;
        justify-content: space-around;

        .info {
            display: flex;
            flex-direction: column;

            p {
                margin-bottom: 0;
            }
        }
    }
}
