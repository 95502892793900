.company {
    .title-row {
        h1 {
            font-weight: 300;
        }
    }

    .page-info, .title-row {
        padding: 0;
    }
}