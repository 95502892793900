/* PODSTRONA - SIDE CONTENT */

.side {
    margin-top: 1.5rem;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    .side-option {
        border: 1px solid $q-mediumgray;
        padding: 1.25rem 1.875rem;
        margin-bottom: 1.25rem;
    }

    .side-option.lang {
        a {
            margin-right: 0.625rem;
            text-decoration: none;

            img {
                margin-bottom: 0.625rem;
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }
        }

        a:last-child {
            margin-right: 0;
        }
    }

    .side-option.info {
        h6 {
            text-align: center;
        }

        span {
            color: $q-gray;
            font-size: 0.875rem;
        }
    }

    .side-option.social_media {
        a {
            margin-right: 1.25rem;

            img {
                @include media-breakpoint-up(md) {
                    max-width: 20px;
                }

                @include media-breakpoint-up(lg) {
                    max-width: unset;
                }
            }
        }

        button {
            display: initial;
        }
    }

    .side-option.download-text {
        h6 {
            text-align: center;
            margin-bottom: 1rem;
        }

        .download-file {
            display: inline-flex;

            img {
                width: 31px;
                height: 42px;
                margin-right: 1rem;
            }

            a {
                color: black;

                span {
                    color: $q-gray;
                    font-size: 0.75rem;
                }
            }
        }
    }

    .side-option.download-media {
        .tiles {
            .tile {
                margin-bottom: 0;
                margin-right: 0.625rem;

                img {
                    max-width: 50px;

                    @include media-breakpoint-up(md) {
                        max-width: 40px;
                    }

                    @include media-breakpoint-up(xl) {
                        max-width: 50px;
                    }
                }
                &.empty {
                    div {
                        width: 50px;
                        height: 33px;
                        @include media-breakpoint-up(md) {
                            width: 40px;
                            height: 27px;
                        }

                        @include media-breakpoint-up(xl) {
                            width: 50px;
                            height: 33px;
                        }
                        background-color: black;

                        &:before,
                        &:after {
                            position: relative;
                            content: "";
                            display: block;
                            width: 12.5px;
                            height: 1px;
                            background: white;
                            top: calc(50%);
                            left: calc(50% - 11px);
                        }

                        &:before {
                            transform: rotate(45deg);
                        }

                        &:after {
                            transform: rotate(-45deg);
                            top: calc(50% - 1px);
                            left: calc(50% + 9px - 11px);
                        }
                    }
                }
            }
        }

        .tile:last-child {
            margin-right: 0;
        }
    }

    .side-option.logotype {
        padding: 3.125rem 0;
        justify-content: center;
        display: flex;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .side-option.contact {
        h6 {
            text-align: center;
        }

        a {
            color: $q-red;
            text-decoration: none;
        }
    }
}
