.company_contact {
    max-width: 1110px !important;

    .contact-section {
        justify-content: space-between;

        .section-title {
            margin-bottom: 1.875rem;

            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }

        .contact-tile {
            .tile__desc {
                h5 {
                    height: unset;
                }
            }
        }
    }
}
