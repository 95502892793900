.epresskit-more {
    .title-row {
        margin-bottom: 2.5rem;
    }
    .offices {
        .form {
            margin-bottom: 1.875rem;
        }

        .office {
            margin-bottom: 1.875rem;

            a {
                color: black;

                .office-logo {
                    height: 234px;
                    border: 1px solid $q-mediumgray;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        max-width: 350px;
                    }
                }

                h5 {
                    margin-top: 1rem;
                    position: relative;

                    &::after {
                        content: url("/layout/default/dist/gfx/icons/arrow.png");
                        transform: scale(0.7);
                        margin-top: -5px;
                        position: absolute;
                        right: 50%;
                        top: 0;
                        opacity: 0;
                        transition: 0.3s ease;
                    }
                }
            }

            &:hover {
                h5 {
                    &::after {
                        opacity: 1;
                        right: 0;
                    }
                }
            }
        }
    }
}
