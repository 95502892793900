.loader-overlay.hidden {
    display: none;
}

.loader-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);

    .loader {
        border: 16px solid #f3f3f3;
        border-top: 16px solid $q-red;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
}