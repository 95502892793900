.form {
    padding: 0;
    margin-bottom: 1.875rem;

    form {
      background-color: $q-lightgray;
      padding: 1.875rem 2.188rem;
      position: relative;

      label {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 1px;
        height: 1px;
      }

      .input-group {
        background-color: white;

        .input-group-prepend {
          align-items: center;
          padding: 0 1.25rem;

          img {
            height: 22px;
          }
        }

        input {
          border: none;
          padding: 1.25rem 0;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .input-group-append {
          select {
            height: 100%;
            padding: 1.25rem;
            margin-right: 1rem;
            color: $q-gray;
            border: none;

            &:focus {
              outline: none;
              box-shadow: none;
              border-color: $q-gray;
            }
          }
          button {
            border: none;
            background-color: $q-mediumgray;
            padding: 1.25rem 2.5rem;
            cursor: pointer;
            display: flex;

            img {
              margin-right: 0.625rem;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }

        @include media-breakpoint-up(xs) {
          input {
            border-right: none;
          }

          .input-group-append {
            display: none;
            width: 100%;
            justify-content: center;

            button {
              justify-content: center;
              width: 100%;
            }
          }
        }

        @include media-breakpoint-up(md) {
          input {
            border-right: 2px solid $q-mediumgray;
          }

          .input-group-append {
            display: flex;
            width: unset;
            justify-content: unset;

            button {
              justify-content: unset;
              width: unset;
            }
          }
        }
      }
    }
  }