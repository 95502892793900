.form-background {
    .form-container {
        .log {
            border-right: 1px solid $q-mediumgray;

            form {
                margin-top: 2.5rem;
                @include media-breakpoint-up(md) {
                    max-width: 445px;
                }

                .form-row {
                    border-bottom: none;
                    padding: 0;
                }

                .form-group {
                    small {
                        font-size: 0.875rem;
                        margin-top: 1.25rem;

                        a {
                            color: $q-red;
                        }
                    }
                }
            }

            .change-for-login {
                p {
                    a {
                        color: $q-red;
                    }
                }
            }

            @include media-breakpoint-up(xs) {
                text-align: center;

                form {
                    text-align: left;

                    .form-text {
                        text-align: center;
                    }

                    .form-group:last-child {
                        margin: auto;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                text-align: left;

                form {
                    .form-text {
                        text-align: left;
                    }

                    .form-group:last-child {
                        margin: unset;
                    }
                }
            }
        }

        .register {
            .register-button {
                display: flex;
                align-items: center;
                margin-bottom: 1.875rem;
                justify-content: space-between;

                h4 {
                    margin-bottom: 0;

                    @include media-breakpoint-up(lg) {
                        padding-right: 15px;
                    }
                }
            }

            h5 {
                margin-bottom: 1.875rem;
            }

            ul {
                list-style: none;
                padding: 0;

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1.25rem;

                    .icon {
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        margin-right: 1.25rem;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }

                li:nth-child(4) {
                    .icon {
                        margin-right: 1.8rem;
                    }
                }
            }

            @include media-breakpoint-up(xs) {
                padding-left: 15px;
                margin-top: 3.5rem;

                .register-button {
                    flex-direction: column;

                    .q-button {
                        margin-top: 1rem;
                    }
                }

                h5 {
                    text-align: center;
                }
            }

            @include media-breakpoint-up(lg) {
                padding-left: 6.875rem;
                margin-top: 0 !important;

                .register-button {
                    flex-direction: initial;

                    .q-button {
                        margin-top: 0 !important;
                    }
                }

                h5 {
                    text-align: left;
                }
            }
        }
    }
}