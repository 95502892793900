.cookies {
    background-color: black;
    color: white;
    padding: 1.875rem 15px;
    display: flex;

    .desc {
        max-width: 1140px;
        align-items: center;
        display: flex;

        p {
            margin: 0;
            font-size: 0.75rem;
            padding-right: 7rem;
            a {
                color: white;
                text-decoration: underline;
            }

            @include media-breakpoint-down(xs) {
                padding-right: 2rem;
                text-align: left;
            }
        }

        .cookies-close {
            background-color: transparent;
            color: white;
            font-size: 0.875rem;
            border: none;
            padding: 0;
            cursor: pointer;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::after {
                content: url('/layout/default/dist/gfx/icons/close_white.png');
                margin-left: 0.625rem;
            }
        }
    }
}