.epresskit {
    .title {
        margin-bottom: 2.625rem;

        @include media-breakpoint-up(xl) {
            margin-bottom: 4.625rem;
        }
    }

    .intro-text {
        h2 {
            margin-bottom: 1.875rem;
        }

        h4 {
            margin-bottom: 1.875rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 2.875rem;
            }
        }

        .q-button {
            padding: 1rem 4.375rem;

            @include media-breakpoint-up(xs) {
                margin-bottom: 1.875rem;
            }

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 1.125rem;
        }
    }
}

.why {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
        margin-top: 5rem;
    }

    .title {
        margin-bottom: 2.5rem;
    }
}

.description {
    margin-top: 4.125rem;
}

.list {
    margin-top: 2.75rem;

    h2 {
        margin-bottom: 1.875rem;
    }

    h4 {
        margin-bottom: 1.5rem;
    }

    ul {
        @include media-breakpoint-up(xs) {
            padding-left: 60px;
        }

        @include media-breakpoint-up(md) {
            padding-left: 15px;
        }
    }
}

.see-other {
    margin-top: 2rem;
    margin-bottom: 3.125rem;

    .highlighted {
        display: flex;
        align-items: center;

        h2 {
            color: $q-red;
            margin-bottom: 0;
        }
    }

    .q-button {
        display: block;
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(xs) {
            font-size: 0.8rem;
            white-space: pre-wrap;
        }

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }

        @include media-breakpoint-up(lg) {
            white-space: unset;
        }
    }
}
