.search {
    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 1045;
        display: none;
    }

    .search-form {
        z-index: 1046;
        background-color: white;
        position: fixed;
        width: 100%;
        top: 0;
        padding: 11.25rem 0;
        display: none;

        .search-close {
            position: absolute;
            top: 1.875rem;
            right: 1.875rem;
            background-color: transparent;
            border: none;
            font-size: 0.875rem;
            cursor: pointer;
            display: flex;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &::after {
                content: url('/layout/default/dist/gfx/icons/close.png');
                margin-left: 0.625rem;
            }
        }

        form {
            position: relative;
            max-width: 730px;
            margin: 0 auto;

            label {
                width: 1px;
                height: 1px;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            }

            input {
                font-size: 2rem;
                color: black;
                border: 0;
                border-bottom: 1px solid $q-mediumgray;
                width: 100%;
                padding-right: 2.75rem;
                padding-bottom: 1.25rem;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }

            .search-submit {
                border: none;
                background-color: transparent;
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
                padding-top: 0.875rem;

                &:focus {
                    box-shadow: none;
                    outline: none;
                }
            }
        }
    }
}