/*BOOTSTRAP*/
@import "./bootstrap/bootstrap";

/*LIBS*/
@import "./fancybox/jquery.fancybox.min";
@import "~swiper/swiper-bundle.css";
@import "~select2/dist/css/select2.min.css";
@import "~magnific-popup/dist/magnific-popup.css";

/*COLORS*/
@import "colors.scss";

/*GLOBAL*/
@import "global.scss";

/*PARTIALS*/
@import "./partials/navbar.scss";
@import "./partials/navbar-presskit.scss";
@import "./partials/navbar_logged.scss";
@import "./partials/form_search.scss";
@import "./partials/news_feed.scss";
@import "./partials/footer.scss";
@import "./partials/search.scss";
@import "./partials/cookies.scss";
@import "./partials/loader.scss";
@import "./partials/mobile-login.scss";
@import "./partials/ajax-filters.scss";
@import "./partials/tiles.scss";
@import "./partials/side_content.scss";
@import "./partials/pagination.scss";
@import "./partials/q-button.scss";
@import "./partials/banner.scss";
@import "./partials/after-login-popup.scss";

/*SUBPAGES*/
@import "./subpages/main_page.scss";
@import "./subpages/search_results.scss";
@import "./subpages/error.scss";
@import "./subpages/news_main.scss";
@import "./subpages/e-presskit.scss";
@import "./subpages/e-presskit-more.scss";
@import "./subpages/presskit_company.scss";
@import "./subpages/presskit_contact.scss";
@import "./subpages/leaders.scss";
@import "./subpages/company.scss";
@import "./subpages/exchange.scss";
@import "./subpages/exchange_main.scss";
@import "./subpages/jobs.scss";
@import "./subpages/newsletter.scss";
@import "./subpages/about_us.scss";
@import "./subpages/publications.scss";
@import "./subpages/presskit_download.scss";

/*FORMS*/
@import "./forms/form_global.scss";
@import "./forms/form_login.scss";
@import "./forms/form_company.scss";

/*USER INTERFACE*/
@import "./UI/ui_global.scss";
@import "./UI/ui_entries_presskit.scss";
@import "./UI/ui_payments.scss";
@import "./UI/ui_settings.scss";

/*AJAX*/
@import "./ajax.scss";
