.navbar {
    background-color: white;

    .navbar-brand {
        @include media-breakpoint-up(xs) {
            margin-right: 0;
            font-size: 0.8rem;
        }

        @media only screen and (min-width: 375px) {
            margin-right: 1rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
        img {
            max-width: 188px;
            max-height: 44px;
        }
    }

    @include media-breakpoint-up(xs) {
        .container.navbar-collapse {
            display: block;
        }

        .container.c-small {
            padding-right: 0;
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .container.c-small {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @include media-breakpoint-up(xl) {
        .container.navbar-collapse {
            display: flex;
        }
    }
}

.intro {
    border-bottom: 1px solid $q-mediumgray;
    transition: 0.3s ease-in-out;
    top: 0;
    min-height: 71px;

    .options {
        .lang,
        .login {
            display: inline-block;

            a {
                border: none;
                background-color: transparent;
                cursor: pointer;
                outline: none;
                box-shadow: none;
                color: black;
            }
        }

        .lang {
            a,
            span {
                color: $q-gray;
            }

            a.active {
                color: black;
            }
        }

        .login {
            align-items: center;
            position: relative;
            padding-left: 1.875rem;

            &::before {
                content: url("/layout/default/dist/gfx/icons/login.png");
                position: absolute;
                left: 0;
                top: 0;
                height: 25px;
                margin-top: -1px;
            }

            .disabled {
                opacity: 0.5;
            }
        }

        .mobile-login-btn,
        .mobile-logged-btn {
            padding: 0.5rem;
        }

        .mobile-logged-btn {
            &::after {
                content: url("/layout/default/dist/gfx/icons/dropdown_arrow.png");
            }
        }
    }

    @include media-breakpoint-up(xs) {
        .options {
            display: flex;
            align-items: center;

            .lang {
                margin-right: 0;
            }

            .login {
                display: none !important;
            }
        }
    }

    @media only screen and (min-width: 375px) {
        .lang {
            margin-right: 1rem !important;
        }
    }

    @include media-breakpoint-up(xl) {
        .options {
            display: initial;
            align-items: center;

            .lang {
                margin-right: 3.125rem !important;
            }

            .login {
                display: inline-block !important;
            }

            .mobile-login-btn,
            .mobile-logged-btn {
                display: none;
            }
        }
    }
}

.menu {
    top: 71px;
    border-bottom: 1px solid $q-mediumgray;
    padding: 0.75rem 1rem;
    z-index: 1029;
    transition: 0.3s ease-in-out;

    @include media-breakpoint-up(xl) {
        padding: 1.25rem 1rem;
    }

    .mobile-toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navbar-toggler {
            border-radius: 0;
        }

        .mobile-desc {
            margin: 0;
            text-transform: uppercase;
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    .navbar-nav {
        li:first-child {
            padding-left: 0;
        }

        .nav-item {
            a {
                color: black;
                text-decoration: none;
                transition: 0.3s ease-in-out;
                position: relative;

                &::before {
                    content: "";
                    width: 0;
                    height: 3px;
                    margin: 5px 0 0;
                    background: $q-red;
                    position: absolute;
                    bottom: -1.5rem;
                    left: 0;
                    transition-duration: 0.5s;
                    opacity: 0;
                }

                &:hover {
                    color: $q-red;

                    &::before {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
        }

        .nav-item.active {
            a {
                color: $q-red;

                &::before {
                    content: "";
                    width: 100%;
                    height: 3px;
                    margin: 5px 0 0;
                    background: $q-red;
                    position: absolute;
                    bottom: -1.5rem;
                    left: 0;
                    transition-duration: 0.5s;
                    opacity: 1;
                }
            }
        }

        .nav-item.disabled {
            opacity: 0.5;
        }
    }

    .search-btn {
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    @include media-breakpoint-up(xs) {
        .collapse {
            display: none !important;
        }

        .collapse.show {
            display: block !important;
        }

        .navbar-nav {
            text-align: center;

            .nav-item {
                padding: 0;
                margin: 0.8rem 0;
                font-size: 1.25rem;

                a {
                    &:hover {
                        &::before {
                            opacity: 0;
                        }
                    }
                }
            }

            .nav-item.active {
                a {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }

        .search-btn {
            margin: auto;
            margin-top: 1.875rem;
        }
    }

    @include media-breakpoint-up(xl) {
        .collapse {
            display: flex !important;
        }

        .navbar-nav {
            text-align: left;

            .nav-item {
                padding: 0 1.5rem;
                margin: 0;
                font-size: 1rem;

                a {
                    &:hover {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-item.active {
                a {
                    &::before {
                        opacity: 1;
                    }
                }
            }
        }

        .search-btn {
            margin: unset;
            margin-top: 0;
        }
    }
}

.intro.scrolled {
    top: -72px;
}

.menu.scrolled {
    top: 0;
}

.menu.scrolled-presskit {
    top: 71px;
}
