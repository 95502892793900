.error-desc {
    p {
        margin-bottom: 3rem;
        span {
            color: $q-red;
        }
    }

    a {
        color: white;
        background-color: $q-red;
        padding: 1.25rem 3.75rem;
        border-radius: 0.2rem;
        text-decoration: none;
    }
}