.tile {
    margin-bottom: 1.25rem;

    a {
        text-decoration: none;
        color: black;

        &:hover {
            .tile__image {
                img {
                    transform: scale(1.1);
                }
            }

            .tile__desc {
                h5 {
                    &::after {
                        opacity: 1;
                        right: 0;
                    }
                }
            }
        }
    }
    &__image {
        overflow: hidden;
        position: relative;
        display: flex;

        .tile__langs {
            position: absolute;
            right: 1rem;
            top: 1rem;
            display: flex;
            flex-wrap: wrap;
            z-index: 10;
            max-width: 50%;

            span {
                margin-right: 0.5rem;
                margin-top: 0.2rem;
            }

            span:last-child {
                margin-right: 0;
            }

            .cz {
                content: url("/layout/default/dist/gfx/icons/cz.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .de {
                content: url("/layout/default/dist/gfx/icons/de.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .en {
                content: url("/layout/default/dist/gfx/icons/en.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .es {
                content: url("/layout/default/dist/gfx/icons/es.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .fr {
                content: url("/layout/default/dist/gfx/icons/fr.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .it {
                content: url("/layout/default/dist/gfx/icons/it.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .pl {
                content: url("/layout/default/dist/gfx/icons/pl.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }

            .ru {
                content: url("/layout/default/dist/gfx/icons/ru.svg");
                height: 32px;
                width: 32px;
                border: 1px solid white;
                border-radius: 100%;
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            display: block;
            margin: auto;
            transition: 0.3s ease-in-out;
        }

        @include media-breakpoint-up(xs) {
            max-height: 22.5rem;
            height: unset;
        }

        @include media-breakpoint-up(lg) {
            height: 22.5rem;
        }
    }

    &__desc {
        margin-top: 1.25rem;

        h5 {
            position: relative;

            @include media-breakpoint-up(md) {
                padding-right: 1.875rem;
            }

            &::after {
                content: url("/layout/default/dist/gfx/icons/arrow.png");
                transform: scale(0.7);
                margin-top: -5px;
                position: absolute;
                right: 50%;
                top: 0;
                opacity: 0;
                transition: 0.3s ease;
            }
        }

        p {
            color: $q-gray;
        }
    }
}

.tile--video {
    position: relative;

    .tile__image {
        height: 22.5rem;
        overflow-y: hidden;

        &::before {
            content: url("/layout/default/dist/gfx/icons/play_btn.png");
            position: absolute;
            top: 8%;
            left: 8%;
            z-index: 1;
        }

        @include media-breakpoint-up(xs) {
            max-height: 22.5rem;
            height: unset;
        }

        @include media-breakpoint-up(lg) {
            height: 22.5rem;
        }
    }
}

.tile--gallery {
    position: relative;
    margin-bottom: 3.75rem;

    .tile__image {
        overflow-y: hidden;

        &::before {
            content: url("/layout/default/dist/gfx/icons/gallery.png");
            position: absolute;
            top: 5%;
            left: 5%;
            z-index: 100;
        }

        @include media-breakpoint-up(xs) {
            max-height: 22.5rem;
            height: unset;
        }

        @include media-breakpoint-up(lg) {
            height: 22.5rem;
        }
    }
}

.tile--product {
    position: relative;
    margin-bottom: 3.75rem;
}

.tile--horizontal {
    margin-bottom: 1.875rem;
    border: 1px solid $q-mediumgray;
    background-color: white;

    @include media-breakpoint-up(xs) {
        padding: 0;
    }

    @include media-breakpoint-up(md) {
        padding: 0 15px;
    }

    @include media-breakpoint-up(lg) {
        max-width: 450px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 540px;
    }

    .tile__image {
        height: auto;
        min-height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.25rem;

        img {
            max-width: 100%;
            width: unset;
            height: 100%;
            max-height: 120px;
            object-fit: contain;
        }
    }

    .tile__desc {
        padding: 1.875rem 30px;
        margin-top: 0;

        h5 {
            display: -webkit-box;
            max-width: 100%;
            height: 48px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 0;

            @include media-breakpoint-up(md) {
                max-width: 80%;
            }
        }

        p {
            color: $q-gray;
            margin-bottom: 0;
        }

        p:last-of-type {
            margin-bottom: 1rem;
        }

        a {
            color: $q-red;
            display: block;
        }
    }
}
