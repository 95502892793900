.navbar-presskit {
    min-height: 72px;
    border-bottom: 1px solid #d7d8d8;
}

.presskit-name {
    top: 71px;
    background-color: white;
    z-index: 1019;
    transition: 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
        padding-bottom: 0rem;
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 0.75rem;
    }

    &::after {
        content: "";
        background-color: white;
        position: absolute;
        z-index: -1;
        top: 0;
        left: -35%;
        width: 170%;
        height: 100%;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 128px;

        img {
            max-height: 90px;
        }
    }

    .presskit-title {
        h1 {
            font-weight: 300;
        }
    }

    @include media-breakpoint-up(xs) {
        padding-top: 0;

        .presskit-title {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            h1 {
                font-size: 1.2rem;
            }
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: 0;
        min-height: 135px;

        .presskit-title {
            h1 {
                font-size: 2.125rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        padding-top: 1.75rem;
        padding-bottom: 2rem;

        .presskit-title {
            h1 {
                font-size: 3.125rem;
            }
        }
    }
}

.menu-presskit {
    border-bottom: none;
    padding: 0.5rem 1rem;
    @include media-breakpoint-up(lg) {
        padding: 0.5rem 2rem;
        padding-bottom: 0;
    }
    border-top: none;
    padding-bottom: 0;
    z-index: 1019;

    .container {
        padding: 1.25rem 0;
        max-width: 1140px;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            display: block;
            margin: auto;
            width: 100%;
            height: 1px;
            background-color: $q-mediumgray;
            z-index: -1;

            @include media-breakpoint-up(md) {
                width: calc(100% - 30px);
            }
        }
    }

    li:first-child {
        padding-left: 0 !important;
    }

    @include media-breakpoint-up(xs) {
        top: 16.8rem;

        .nav-item {
            padding: 0;
        }
    }

    @include media-breakpoint-up(md) {
        top: 11.8rem;
    }

    @include media-breakpoint-up(xl) {
        top: 254px;

        .nav-item {
            padding: 0 2.1rem !important;
        }
    }
}

.presskit-name.scrolled {
    top: -188px;
}

.menu.scrolled-up {
    top: 71px;
}
