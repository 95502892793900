.news_main-home {
    margin-top: 1.875rem;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 1.875rem;
    }

    @include media-breakpoint-up(xl) {
        padding: 0 3.875rem;
    }

    .news-big,
    .news-small {
        position: relative;

        a {
            color: white;
            text-decoration: none;

            &:hover {
                .news__desc {
                    h1,
                    h5 {
                        &::after {
                            opacity: 1;
                            right: 0;
                        }
                    }
                }
            }

            .news__image {
                position: relative;
                @include media-breakpoint-up(xl) {
                    height: 100%;
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    background: linear-gradient(
                        0deg,
                        rgba(0, 0, 0, 1) 0%,
                        rgba(0, 0, 0, 0) 50%
                    );
                }
            }

            .news__desc {
                h1,
                h5 {
                    position: relative;
                    transition: 0.3s ease-in-out;
                    padding-right: 40px;
                    min-width: calc(100% + 1.25rem);

                    &::after {
                        content: url("/layout/default/dist/gfx/icons/arrow.png");
                        margin-top: -5px;
                        position: absolute;
                        right: 50%;
                        top: 0;
                        opacity: 0;
                        transition: 0.3s ease;
                        filter: contrast(0) brightness(2.5);
                    }
                }

                p {
                    color: $q-gray;
                }

                @include media-breakpoint-up(xs) {
                    position: initial;
                    margin: 0;
                    padding: 0;
                    margin-top: 1.25rem;

                    h1,
                    h5 {
                        color: black;
                        font-size: 1.25rem;
                    }
                }

                @include media-breakpoint-up(lg) {
                    position: absolute;
                    bottom: 1%;
                    padding: 0 1.875rem;
                    margin-top: 0 !important;

                    h1,
                    h5 {
                        color: white;
                        font-size: 1.25rem;
                    }

                    h1 {
                        font-size: 3.125rem;
                    }
                }
            }
        }
    }

    .news-big.tile--video {
        .news__image {
            &::before {
                content: url("/layout/default/dist/gfx/icons/play_btn.png");
                position: absolute;
                top: 5%;
                left: 5%;
            }
        }
    }

    .news-big {
        .news__image {
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                max-height: 100%;
            }
        }

        .news__desc {
            h1 {
                font-size: 3.125rem;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-left: 40px;
            padding-right: 0;
        }
    }

    .news-multi {
        .news-small.tile--video {
            .news__image {
                &::before {
                    content: url("/layout/default/dist/gfx/icons/play_btn.png");
                    position: absolute;
                    top: 5%;
                    left: 5%;
                    display: block;
                    width: unset;
                    height: unset;
                    background: transparent;
                }
            }
        }

        .news-small {
            margin-bottom: 0.938rem;
            .news__image {
                @include media-breakpoint-up(lg) {
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        display: inline-block;
                        background: rgba(0, 0, 0, 0.4);
                    }
                }

                @include media-breakpoint-up(xl) {
                    &::before {
                        display: none;
                    }
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .news__desc {
                h5 {
                    &::after {
                        transform: scale(0.7);
                    }
                }
            }
        }

        .news-small:last-child {
            margin-bottom: 0;
        }
    }

    &.empty {
        min-height: 75vh;
    }
}

.video {
    background-color: $q-darkblue;
    color: white;
    padding: 3.75rem 15px;

    .title {
        margin-bottom: 3.125rem;
    }

    .swiper-container {
        height: 415px;

        .swiper-wrapper {
            .swiper-slide {
                .tile--video {
                    a {
                        .tile__image {
                            height: unset !important;

                            &::before {
                                top: 40%;
                                left: 45%;
                            }
                        }

                        .tile__desc {
                            margin-top: 1rem;

                            h5 {
                                color: white;

                                &::after {
                                    filter: contrast(0) brightness(2.5);
                                }
                            }
                        }

                        &:hover {
                            h5 {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            bottom: 15%;

            @include media-breakpoint-up(md) {
                bottom: 10px;
            }

            .swiper-pagination-bullet {
                width: 2.5rem;
                height: 3px;
                border-radius: 0;
                background: white;
            }
        }
    }
}

.announcements {
    background-color: $q-lightgray;
    padding: 0 1.875rem;
    padding-top: 3.75rem;
    padding-bottom: 4.375rem;

    .categories {
        margin-top: 2.5rem;
        margin-bottom: 1.875rem;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                cursor: pointer;
                display: inline;
                background-color: white;
                text-transform: uppercase;
                padding: 0.625rem 1.25rem;
                border-radius: 0.2rem;
                transition: 0.2s ease-in-out;

                a {
                    color: $q-gray;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 0.75rem;
                    transition: 0.2s ease-in-out;
                }

                &:hover {
                    background-color: $q-red;

                    a {
                        color: white;
                    }
                }
            }

            li:last-child {
                margin-right: 0;
            }
        }

        @include media-breakpoint-up(xs) {
            ul {
                display: flex;
                flex-direction: column;

                li {
                    margin: 1rem 0;
                    margin-right: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            ul {
                display: initial;

                li {
                    margin: 0;
                    margin-right: 0.625rem;
                }
            }
        }
    }

    .feed {
        .jobs-big,
        .jobs-small {
            background-color: white;

            a {
                color: black;
                text-decoration: none;

                &:hover {
                    h6 {
                        text-decoration: underline;
                    }
                }
            }

            .jobs__desc {
                padding: 0 1.875rem;

                h6 {
                    font-size: 1.063rem;
                }

                p {
                    color: $q-gray;
                }
            }
        }

        .jobs-big {
            padding: 0;
            .jobs__logo {
                height: 234px;
                display: flex;

                img {
                    width: initial;
                    display: block;
                    margin: auto;
                    max-width: 350px;
                    height: auto;
                }
            }

            @include media-breakpoint-up(xs) {
                margin: 1rem 0;
            }

            @include media-breakpoint-up(xl) {
                margin: 0;
                margin-right: 1.875rem;
            }
        }

        .jobs-small {
            max-height: 120px;
            padding: 1.25rem 0;
            margin-bottom: 0.625rem;
        }

        .jobs-small:last-child {
            margin-bottom: 0;
        }

        .more {
            margin-top: 2.5rem;

            a {
                color: $q-red;
                font-size: 1.063rem;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .jobs {
        max-width: 350px;
    }

    .jobs.jobs-multi {
        max-width: 700px;
    }
}

@include media-breakpoint-up(xl) {
    .jobs.jobs-multi {
        max-width: 350px;
    }
}
