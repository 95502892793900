.navbar.intro {
    .login.logged-in {
        &::after {
            content: url('/layout/default/dist/gfx/icons/dropdown_arrow.png');
        }
        a {
            text-decoration: none;
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            z-index: 1100;
            .dropdown-item {
                display: flex;
                align-items: center;
            }

            .dropdown-item.presskit {
                &::after {
                    content: url('/layout/default/dist/gfx/icons/presskit-icon.png');
                    margin-left: 0.5rem;
                    height: 17px;
                }
            }

            .log-out {
                &::before {
                    content: url('/layout/default/dist/gfx/icons/logout.png');
                    margin-right: 0.5rem;
                    height: 23px;
                }
            }
        }
    }
}