.footer {
    padding-bottom: 3.125rem;

    .logotypes {
        margin-bottom: 3.75rem;

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-up(lg) {
                justify-content: unset;
            }

            img {
                max-width: 100%;
            }
        }

        .logo.black {
            img {
                filter: brightness(0);
            }
        }

        .partners {
            display: flex;
            align-items: center;
            flex-direction: column;

            a {
                margin: 0.5rem 0;
            }

            h5 {
                margin-bottom: 0;
            }

            img {
                max-width: 170px;
            }

            .bigger {
                img {
                    max-height: 60px;
                    width: 60px;
                }
            }

            @include media-breakpoint-up(md) {
                flex-direction: row;

                a {
                    margin: 0 0.5rem;
                }
            }
        }

        .social_media {
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }

            a {
                display: flex;
                align-items: center;
                img {
                    margin: 0 0.938rem;
                }
            }
        }

        .contact-info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info {
                p,
                a {
                    margin: 0;
                }

                a {
                    color: $q-red;
                    display: block;
                }
            }

            @include media-breakpoint-up(xs) {
                flex-direction: column;

                .info {
                    margin-bottom: 1rem;
                }

                .info:last-child {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(lg) {
                flex-direction: initial;

                .info {
                    margin-bottom: 0;
                }
            }
        }
    }
    .sub {
        align-items: center;

        .copyright {
            p {
                font-size: 0.85rem;
            }
        }

        .footer-list {
            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 1.4rem;
                }

                li {
                    display: inline;
                    margin-right: 1.25rem;

                    a {
                        color: black;
                        text-decoration: none;
                        font-size: 0.85rem;
                    }
                }

                li:last-child {
                    margin-right: 0;
                }
            }
        }

        .realization {
            display: flex;
            flex-direction: column;
            text-align: right;
            min-height: 40px;

            span {
                font-size: 0.85rem;
                line-height: 1.2;
                a {
                    font-size: 0.85rem;
                    color: black;
                    text-decoration: none;
                }
            }

            @include media-breakpoint-up(xs) {
                text-align: center;
                justify-content: center;
            }

            @include media-breakpoint-up(xl) {
                justify-content: initial;
                text-align: right;
                padding-left: 5px;
            }
        }
    }

    @include media-breakpoint-up(xs) {
        text-align: center;
        padding-top: 1.75rem;

        .logotypes {
            .logo {
                margin-bottom: 1.875rem;
            }
            .partners {
                margin-bottom: 1.875rem;
                justify-content: center;

                h5 {
                    margin-right: 0.875rem;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        text-align: initial;
        padding-top: 3.75rem;

        .logotypes {
            .logo {
                margin-bottom: 0;
            }
            .partners {
                margin-bottom: 0;
                justify-content: unset;

                h5 {
                    margin-right: 1.875rem;
                }
            }
        }
    }
}
