.news_main {
    .page-title {
        p {
            display: inline;
            color: $q-gray;
            margin-right: 1.25rem;
        }

        @include media-breakpoint-up(xs) {
            h1 {
                font-size: 2rem;
            }
        }

        @include media-breakpoint-up(md) {
            h1 {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
            }
        }

        @media only screen and (min-width: 1400px) {
            h1 {
                font-size: 3.125rem;
            }
        }
    }

    .news-desc {
        margin-top: 2.5rem;
        padding-bottom: 1.125rem;
        border-bottom: 1px solid $q-mediumgray;

        @include media-breakpoint-up(md) {
            padding-bottom: 3.125rem;
        }

        .desc {
            .video-wrapper {
                margin-bottom: 1.25rem;
                a {
                    margin-top: 1.25rem;
                }
            }
            .main-image {
                position: relative;

                .overlay-download {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    background-color: rgba(0, 0, 0, 0.5);
                    width: 100%;
                    height: 100%;
                    padding: inherit;
                    opacity: 0;
                    transition: 0.3s ease-in-out;
                    z-index: 1;

                    .download {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        right: 0;
                        width: 48px;
                        margin: auto;
                    }

                    .social-upload {
                        display: flex;
                        max-width: 20%;
                        margin-left: auto;
                        margin-right: 1rem;
                        margin-top: 2rem;
                        justify-content: space-evenly;

                        a {
                            img {
                                width: unset;
                                height: unset;
                            }
                        }
                    }
                }
                .overlay-download.selected,
                .overlay-download:hover {
                    opacity: 1;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
                p {
                    margin-top: 1rem;
                    color: $q-gray;
                    font-size: 0.85rem;
                }
            }

            h4 {
                margin-bottom: 1.35rem;
            }

            p {
                font-size: 1.125rem;
            }

            ul {
                li {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.gallery {
    position: relative;
    padding-bottom: 2.5rem;
    margin-top: 3.125rem;

    .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .overlay-download {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            max-width: 350px;
            height: calc(100% - 2.5rem);
            padding: inherit;
            opacity: 0;
            transition: 0.3s ease-in-out;
            z-index: 1;

            @include media-breakpoint-up(md) {
                max-width: 540px;
            }

            @media only screen and (min-width: 1025px) {
                max-width: 820px;
            }

            .download {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                width: 48px;
                margin: auto;
            }

            .social-upload {
                display: flex;
                max-width: 20%;
                margin-left: auto;
                margin-right: 1rem;
                margin-top: 2rem;
                justify-content: space-evenly;
            }
        }

        .overlay-download.selected,
        .overlay-download:hover {
            opacity: 1;
        }

        img {
            object-fit: cover;
            max-height: 512px;
            margin: 0 auto;
            display: block;
        }

        p {
            margin: 0;
            margin-top: 1rem;
            font-weight: bold;
            min-height: 24px;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: 55px;
        height: 55px;
        top: 40%;
        background-color: white;
        border-radius: 100%;

        &::after {
            content: url("/layout/default/dist/gfx/icons/arrow.png");
            position: relative;
            top: -1px;
        }

        @include media-breakpoint-up(md) {
            top: 45%;
        }
    }

    .swiper-button-prev {
        &::after {
            transform: scaleX(-1);
        }
    }
}

@media only screen and (min-width: 350px) {
    .gallery {
        .swiper-slide {
            img {
                max-width: 350px;
            }
        }

        .mini-gallery {
            .download-photos {
                div {
                    button,
                    a {
                        display: block;
                        margin: 1.25rem auto;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .gallery {
        .swiper-slide {
            img {
                max-width: 540px;
            }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .gallery {
        .swiper-slide {
            img {
                max-width: 820px;
            }
        }

        .mini-gallery {
            .download-photos {
                div {
                    button,
                    a {
                        display: initial;
                        margin: 0 1.875rem;
                    }
                }
            }
        }
    }
}

.mini-gallery {
    margin-top: 3.125rem;

    .miniature {
        margin-bottom: 1.875rem;
        position: relative;
        transition: 0.2s ease;

        .overlay-download {
            position: absolute;
            top: 0;
            left: 15px;
            background-color: rgba(0, 0, 0, 0.5);
            width: calc(100% - 30px);
            height: 100%;
            padding: inherit;
            opacity: 0;
            transition: 0.3s ease-in-out;
            z-index: 1;

            input {
                width: 1rem;
                height: 1rem;
                margin-top: 1.25rem;
            }

            .download {
                position: absolute;
                top: 40%;
                left: 0;
                right: 0;
                width: 48px;
                margin: auto;
            }

            .social-upload {
                float: right;
                margin-top: 1rem;

                a {
                    margin-right: 1rem;
                }

                a:last-child {
                    margin-right: 0;
                }
            }
        }

        .overlay-download.selected,
        .overlay-download:hover {
            opacity: 1;
        }

        & > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        img {
            max-width: 100%;
            max-height: 235px;
        }
    }

    .miniature.currentTile {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            display: block;
            width: calc(100% - 30px);
            height: 8px;
            background-color: $q-red;
        }
    }

    .download-photos {
        div {
            padding: 0 15px;

            button,
            a {
                margin: 0 1.875rem;
                color: $q-red;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.see-more {
    background-color: $q-lightgray;
    padding: 1.875rem 15px;

    .row {
        max-width: 1110px;
        margin: 0 auto;
        justify-content: space-between;

        .title {
            margin-bottom: 2.5rem;
        }
    }
}
