.company_download {
    max-width: 1110px !important;

    .download-section {
        justify-content: space-between;

        .tile--horizontal {
            .tile__desc {
                h5 {
                    font-size: 1.063rem;
                    overflow: visible;
                    white-space: unset;
                }
            }
        }
    }
}